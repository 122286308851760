<template>
  <v-row
    class="product-tab-selection"
  >
    <v-col
      cols="12"
      class="subtitle-2 text--gray-200 py-0 mb-2"
    >
      {{ $t('Role.createEditRolePage.products') }}:
    </v-col>
    <v-col
      cols="12"
      class="product-tab-selection__products pa-0 pl-2"
    >
      <v-col
        v-for="product in products"
        :key="product.id"
        cols="12"
        class="product-tab-selection__product-tab px-2 py-1"
        :class="getProductRowClasses(product)"
      >
        <div
          class="product-tab-selection__product-marker"
          :class="productTabSelectionClass(product)"
        />
        <span>
          <b>Stilingue</b> {{ product.name }}
          <StIcon
            v-if="product.isLocked"
            v-tooltip="$t('Role.createEditRolePage.noPermission')"
            icon-name="lock"
            class="product-tab-selection__lock"
          />
        </span>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import StIcon from '@stilingue/st-icon/StIcon';
import productsMixin from '@/mixins/products.mixin';

export default {
  name: 'ProductTabSelection',
  components: {
    StIcon
  },
  mixins: [
    productsMixin
  ],
  methods: {
    getProductRowClasses(product) {
      return {
        'product-tab-selection__product-tab--selected': product.active
      };
    },
    productTabSelectionClass (product) {
      return `product-tab-selection__product-marker--${product.id}`;
    }
  }
};
</script>

<style scoped lang="scss">
  .product-tab-selection__product-marker {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 10px;

    &--boards {
      background-color: #98999d;
    }

    &--tickets {
      background-color: #e9de33;
    }
  }

  .product-tab-selection__product-tab {
    font-size: 16px;
    color: #707076;

    &--selected {
      background-color: #f8f8f8;
    }
  }

  .product-tab-selection__lock {
    font-size: 12px;
    color: #a4a4a4;
    margin-bottom: 2px;
  }
</style>
