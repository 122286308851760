
export default {
  computed: {
    products() {
      return [
        {
          id: 'tickets',
          name: 'Tickets',
          active: true,
          isLocked: false
        }
      ];
    }
  }
};
