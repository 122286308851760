var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pa-0 mt-6 role-permissions__submit-bar" },
    [
      _c(
        "v-btn",
        {
          staticClass: "role-permissions__submit-button ma-2",
          attrs: {
            outlined: "",
            rounded: "",
            color: "grey",
            disabled: _vm.loading
          },
          on: { click: _vm.goBack }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("Role.cancel")) + "\n  ")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "role-permissions__submit-button ma-2",
          attrs: {
            rounded: "",
            color: "primary",
            disabled: _vm.disabled,
            loading: _vm.loading
          },
          on: {
            click: function($event) {
              return _vm.$emit("on-submit-clicked")
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.confirmBtnText) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }