<template>
  <v-row
    class="pa-0 mt-6 role-permissions__submit-bar"
  >
    <v-btn
      outlined
      rounded
      color="grey"
      class="role-permissions__submit-button ma-2"
      :disabled="loading"
      @click="goBack"
    >
      {{ $t('Role.cancel') }}
    </v-btn>
    <v-btn
      rounded
      color="primary"
      class="role-permissions__submit-button ma-2"
      :disabled="disabled"
      :loading="loading"
      @click="$emit('on-submit-clicked')"
    >
      {{ confirmBtnText }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: 'SubmitBar',
  props: {
    confirmBtnText: {
      type: String,
      required: true
    },
    goBackRoute: {
      type: String,
      default: 'role-list'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ name: this.goBackRoute })
        .catch(/*istanbul ignore next*/ () => {});
    }
  }
};
</script>

<style lang="scss">
  .role-permissions {
    &__submit-bar {
      float: right;
    }

    &__submit-button .v-btn__content {
      font-weight: 600;
    }
  }
</style>
