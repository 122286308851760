var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "product-tab-selection" },
    [
      _c(
        "v-col",
        {
          staticClass: "subtitle-2 text--gray-200 py-0 mb-2",
          attrs: { cols: "12" }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("Role.createEditRolePage.products")) +
              ":\n  "
          )
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "product-tab-selection__products pa-0 pl-2",
          attrs: { cols: "12" }
        },
        _vm._l(_vm.products, function(product) {
          return _c(
            "v-col",
            {
              key: product.id,
              staticClass: "product-tab-selection__product-tab px-2 py-1",
              class: _vm.getProductRowClasses(product),
              attrs: { cols: "12" }
            },
            [
              _c("div", {
                staticClass: "product-tab-selection__product-marker",
                class: _vm.productTabSelectionClass(product)
              }),
              _c(
                "span",
                [
                  _c("b", [_vm._v("Stilingue")]),
                  _vm._v(" " + _vm._s(product.name) + "\n        "),
                  product.isLocked
                    ? _c("StIcon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.$t(
                              "Role.createEditRolePage.noPermission"
                            ),
                            expression:
                              "$t('Role.createEditRolePage.noPermission')"
                          }
                        ],
                        staticClass: "product-tab-selection__lock",
                        attrs: { "icon-name": "lock" }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }